import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS
import './App.css'; // Custom styles

// Import the Google Fonts
import '@fontsource/arvo';
import '@fontsource/comic-neue';

function App() {
  const handleRSVP = () => {
    // Open the Microsoft Form link in a new tab
    window.open('https://forms.office.com/r/0UU5xUd6Tp?origin=lprLink', '_blank');
  };

  return (
    <div className="App">
      <header className="container">
        {/* Your header content */}
      </header>
      <main className="container text-center my-5">
        <div className="row justify-content-center">
          <div className="container text-left">
            <div className="row">
              <div className="col"></div>
              <div className="col-8">
                <h1 className="text-white mt-4">Noel 2024</h1>
                <h2 className="lead text-white mt-4">Come and celebrate.</h2>
              </div>
              <div className="col"></div>
            </div>
            <div className="row">
              <div className="col"></div>
              <div className="col-10">
                <h3 className="follow text-white mt-1">
                  🌟✨ Comme say a bonjour, restez pour le supper, or stay jusqu'à
                  minuit—l'étoile du Nord veille et le mistletoe est prêt pour les
                  bisous! 🎅💋
                </h3>
              </div>
              <div className="col"></div>
            </div>
            <div className="row justify-content-left">
              <div className="col"></div>
              <div className="col-8"></div>
              <div className="col"></div>
            </div>
            <div className="row">
              <div className="col"></div>
              <div className="col">
                <div className="card secret-santa-card">
                  <img
                    src="https://ournoel.rocks/secret_santa_boot.gif"
                    className="card-img-top"
                    alt="Secret Santa Card"
                  />
                  <div className="card-body">
                    <h5 className="card-title">Le Secret Santa</h5>
                    <p className="card-text">
                      🎄✨ Laissez les cadeaux rouler, Secret Santa's on the way, cher!
                      🎅🎁.
                    </p>
                    <button className="btn btn-primary" onClick={handleRSVP}>
                      📨RSVP✅
                    </button>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="card food-card">
                  <img
                    src="https://ournoel.rocks/ChristmasFeastWithWine.gif"
                    className="card-img-top"
                    alt="Buffet"
                  />
                  <div className="card-body">
                    <h5 className="card-title">Le Food</h5>
                    <p className="card-text">
                      🍗🎄 Buffet never stops! Mangez toute la journée, laissez-vous aller
                      ! 🥖✨
                    </p>
                    <button className="btn btn-primary">🤤Burp💨</button>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="card pere-noel-card">
                  <img
                    src="https://ournoel.rocks/santa_mash.gif"
                    className="card-img-top"
                    alt="Pere Noel Card"
                  />
                  <div className="card-body">
                    <h5 className="card-title">Le Père Noël</h5>
                    <p className="card-text">
                      🎄✨ Ho là là, le Père Noël brings something special pour vous! I
                      guarantie! 🎁🎅
                    </p>
                    <button className="btn btn-primary" onClick={handleRSVP}>
                      📨RSVP✅
                    </button>
                  </div>
                </div>
              </div>
              <div className="col"></div>
            </div>
          </div>
        </div>
        <div className="row justify-content-center"></div>
      </main>
    </div>
  );
}

export default App;
